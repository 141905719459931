
import {rzSimpleTable, rzButton, rzIconFlightStart, rzIconFlightEnd, rzSelect, rzDateCalendarInput, rzPreloader, rzTabs} from 'razlet-ui';
import weekdays from '~/constants/weekdays';

export default {
  name: 'schedule',
  components: {
    rzSimpleTable,
    rzButton,
    rzIconFlightStart, 
    rzIconFlightEnd,
    rzSelect,
    rzDateCalendarInput,
    rzPreloader,
    rzTabs,
  },
  props: {
    content: {
      type:Object,
      required:true,
    },
  },
  data() {
    return {
      tableHead: [
        {name: 'Авиакомпания'},
        {name: 'Рейс'},
        {name: 'Направление'},
        {name: 'Дни вылета'},
        {name: 'Вылет'},
        {name: 'Прилет'},
        {name: 'Навигация'},
      ],
      tableHeadMobile: [
        {name: 'Данные рейса'},
        {name: 'Время'},
      ],
      activeTab: 'departure',
      selectedDay: null,
      tabs: [
        {
          value: 'departure',
          title: 'Вылет',
          icon: 'rz-icon-flight-start',
        },
        {
          value: 'arrival',
          title: 'Прилет',
          icon: 'rz-icon-flight-end',
        },
      ],
      date: {},
      airline: null,
      schedule: null,
      selectKey: 0,
      currentWeekday: null,
    };
  },
  computed: {
    airlineOptions() {
      const companies = Array.from(new Set(this.schedule[this.activeTab].map(item => item.company)));
      return companies.map(company => {
        return {
          label: company,
          value: company,
        };
      });
    },
    airlines() {
      if (!this.airline) return [];
      const keys = Object.keys(this.airline);
      return keys.filter(key => this.airline[key]);
    },
    filteredSchedule() {
      return this.schedule[this.activeTab]
        .filter(flight => {
          if (!this.airlines.length) return true;
          return this.airlines.includes(flight.company);
        })
        .filter(flight => {
          if (!Object.keys(this.date).length) return true;
          const activeDays = flight.days
            .filter(day => day.active)
            .map(day => day.id);
          return activeDays.includes(this.currentWeekday);
        }).filter(flight => {
          if (!Object.keys(this.date).length) return true;
          const filterDate = `${this.date.year}-${this.date.month.value}-${this.date.date}`;

          return flight.begin <= filterDate  && filterDate <= flight.end;
        });
    },
    isEmptyFilter() {
      return (!this.airline || (Object.values(this.airline).every(item => !item))) && !Object.keys(this.date).length;
    },
  },
  watch: {
    date: {
      handler(value) {
        if (Object.keys(value).length) this.currentWeekday = this.getWeekday(value);
      },
      deep:true,
    },
  },
  created() {
    if (this.content) {
      const schedule = {
        arrival: this.formatElements(this.content.arrival_elements),
        departure: this.formatElements(this.content.departure_elements),
      };
      this.$set(this, 'schedule', schedule);
    }
  },
  methods: {
    formatDate(date) {
      const arr = date.split('-');
      const year = arr[0].substring(2, 4);
      return `${arr[2]}.${arr[1]}.${year}`;
    },
    getWeekday(date) {
      const value = new Date(`${date.month.value}/${date.date}/${date.year}`);
      return value.getDay();
    },
    formatElements (arr) {
      return arr.map(flight => {
        return {
          ...flight,
          beginFormatted: this.formatDate(flight.begin),
          endFormatted: this.formatDate(flight.end),
          days: weekdays.map(weekday => {
            return {
              name: weekday.short,
              id: weekday.num,
              active: flight['day_of_week'].includes(weekday.en),
            };
          }),
        };
      });
    },
    resetFilter() {
      this.$set(this, 'airline', null);
      this.selectKey += 1;
      this.date = {};
    },
    switchTab(tab) {
      this.activeTab = tab;
    },
    changeDay(day) {
      this.selectedDay = day;
    },
  },
};
